<template>
  <v-container>
    <div class="mt-10" align="center" justify="center">
      <v-img width="400px" class="mb-50" contain src="../../assets/images/Logo_white.png"></v-img>
    </div>
    <div class="mt-10 text-h5 text-sm-h4" align="center" justify="center">
      What do you want to know?
    </div>
    <v-expansion-panels v-model="openedPanel" class="mt-10" focusable popout>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-subtitle-1 text-sm-h5">
          <template v-slot:actions>
            <v-icon>
              mdi-comment-question-outline
            </v-icon>
          </template>
          What is Masspredict?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-5">Masspredict is all about asking the right questions.</p>
          <p>Say I ask you why you drive a Porsche. You'll tell me it's a beautiful piece of engineering. So I'll ask you a different question instead: why does your neighbour drive a Porsche? We're willing to bet the answer to that question would be different - and more truthful.</p>
          <p>This is one of the secrets behind so-called “social-circle surveys”: don't ask people about themselves, ask them about others, such as people in their social circles. Not only will that give you more truthful answers, but you'll also tap into people's social networks, and thereby indirectly sample a much larger group than the one person you talk to, referred to in the literature as “implicit super samples”. Research shows that this reduces a variety of biases that usually affect traditional surveys (<a href="https://www.sciencedirect.com/science/article/abs/pii/S0261379421001451" target="_blank">Ahlstrom-Vij (2022)</a>.</p>
          <p>But that's not it. In addition to asking the right questions, you also want to aggregate the responses in as efficient a manner as possible. Masspredict does that through something known as a prediction market. You can think of it like a stock market for future events: if lots of people think some event is likely, the price goes up. If lots of people think it unlikely, the prize goes down. The prize is then interpreted as the relevant group's probability judgement. It's been shown that prediction markets tend to give highly accurate outputs, and moreover outperform alternative aggregation methods, such as simply taking the mean estimate (<a href="http://journal.sjdm.org/18/18919/jdm18919.pdf" target="_blank">Dana et al. 2019</a>).</p>
          <p>Masspredict makes use of so-called self-resolving prediction markets in particular, as developed and validated in <a href="http://ubplj.org/index.php/jpm/article/view/1687" target="_blank">Ahlstrom-Vij (2019)</a>. These have the added benefit that they do not require waiting for the relevant events "bets" on to take place or not before resolving the markets, and can as such be used in long-term forecasting as well as in evaluating counterfactuals ("What would have happened, had we acted differently?")</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-subtitle-1 text-sm-h5">
          <template v-slot:actions>
            <v-icon>
              mdi-cogs
            </v-icon>
          </template>
          How does it work?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-5">Most prediction markets rely on a fairly complex, stock market style interface that alienates large numbers of people not comfortable with that type of interface. That's why Masspredict instead uses a conversational interface, taking the form of a simple and intuitive conversation.</p>
          <p>You can customise exactly how the conversation is to proceed, but the standard flow goes like this:</p>
          <ul>
            <li>Participants are asked to estimate the probability of something, which can take the form either of a binary matter (“Will this happen?”) or a number of scenarios (“Will A, B, or C happen?”).</li>
            <li>Participants are then also asked to elaborate on their estimate in free text, to offer you data not only on what they think but also why they think it.</li>
            <li>A participant's estimate is weighted based on how informative their elaboration is, which means that people sitting on valuable information have a greater influence on the group's estimate.</li>
            <li>Finally, in keeping with the conversational format, participants are also provided with representative elaborations offered for different estimates and given the opportunity to update their own in light of what they thereby learn, in line with how prediction market participants traditionally revisit their bets on the basis of new information.</li>
          </ul>
          <p class="mt-2">Since participants are asked about their expectations of others rather than their own intentions or preferences, each participant in effect provides not one data point (as in a traditional survey) but several, in factoring in every other person about whom they can speak knowledgeably (as on a prediction market). This phenomenon has been studied in relation to "social circle surveys" (<a href="https://www.sciencedirect.com/science/article/abs/pii/S0261379421001451" target="_blank">Ahlstrom-Vij 2022)</a> and in political forecasting (e.g., <a href="https://econpapers.repec.org/article/cupbjposi/v_3a51_3ay_3a2021_3ai_3a1_3ap_3a60-67_5f5.htm" target="_blank">Murr, Stegmaier and Lewis-Beck 2021</a>; and <a href="https://www.brookings.edu/wp-content/uploads/2016/06/01-voter-expectations-wolfers.pdf" target="_blank">Rothschild and Wolfers 2012</a>).</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-subtitle-1 text-sm-h5">
          <template v-slot:actions>
            <v-icon>
              mdi-pencil-outline
            </v-icon>
          </template>
          How do I create a study?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-5">To create a new study, do the following:</p>
          <ul>
            <li>Navigate to the YOUR STUDIES page from the menu on the left.</li>
            <li>Click CREATE NEW STUDY on the top right.</li>
          </ul>
          <p class="mt-2">Then it's time to input some BASIC INFORMATION about your study:</p>
          <ul>
            <li>Give the study a TITLE. Only you will be able to see this, not participants.</li>
            <li>Optional: Write a brief INTRODUCTION, introducing the study to your participants.</li>
            <li>Optional: Include a REDIRECT LINK to where participants should be sent when they have completed the study (e.g., if you are using a panel company currently not integrated with Masspredict).</li>
          </ul>
          <p class="mt-5">Next, it's time to add questions:</p>
          <ul>
            <li>Click ADD A QUESTION.</li>
            <li>Pick the type of question you would like to ask: a BINARY QUESTION, which can be answered with a "yes" or a "no"; a SCENARIO QUESTION, which asks about a number of scenarios; an OPEN QUESTION, which collects open-ended responses; or a DEMOGRAPHIC QUESTION, which collects demographic information that you can then use to segment your results.</li>
            <li>For BINARY QUESTIONS, you can make use of our automated QUESTION ASSIST, which helps you ask the right type of question.</li>
            <li>Once you've picked a question, briefly introduce it to your participants, by offering any relevant CONTEXT that you want them to take into account when answering.</li>
            <li>Click DONE.</li>
            <li>Add further questions as necessary, and then click DONE again.</li>
          </ul>
          <p class="mt-5">Congratulations! You've created a Masspredict study.</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-subtitle-1 text-sm-h5">
          <template v-slot:actions>
            <v-icon>
              mdi-rocket
            </v-icon>
          </template>
          How do I launch a study?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-5">A newly created study will be in DRAFT mode. This means that you can:</p>
          <ul>
            <li>Edit study details, as well as edit, delete, or reorder questions or scenario options.</li>
            <li>Use the PREVIEW feature to either test run the study yourself, or share a test link with others.</li>
            <li>Change the LANGUAGE of the study to enable participants to take it in their native tongue.</li>
            <li>Go into the SETTINGS menu to change the COLOR THEME, turn on EMAIL COLLECTION (e.g., in case you need to get in touch to distribute an incentive or reward), and add a CUSTOM LOGO to the study.</li>
            <li>Toggle whether the group's current estimate should be shared with participants, and if so whether it should be shared before they answer the question or after.</li>
            <li>Click LAUNCH when you are ready to launch your study and recruit participants.</li>
          </ul>
          <p class="mt-2">Click LAUNCH when you are happy with your study. Note that this will delete any test responses you'll have generated in DRAFT mode, and mean that you can no longer edit it.</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-subtitle-1 text-sm-h5">
          <template v-slot:actions>
            <v-icon>
              mdi-account-group-outline
            </v-icon>
          </template>
          How do I recruit participants?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-5">Once your study is launched, click RECRUIT to get participants in your study. You have a couple of options:</p>
          <ul>
            <li>Distribute the PUBLIC LINK to participants.</li>
            <li>Share it through SOCIAL CHANNELS, such as Slack, WhatsApp, etc.</li>
            <li>Get participants through our fully integrated panels. Note: this option requires spending panel credits. See <span class="text-decoration-underline" @click="openPanel(7)">"How do I get credits?"</span> for more details.</li>
            <li>Embed the study on your website using the WEBSITE WIDGET option.</li>
          </ul>
          <p class="mt-2">How many participants will you need? Since Masspredict relies on asking predictive questions about expectations rather than about respondents' intentions (as in a traditional survey), you can expect accurate results even in fairly small samples of 100 participants or so. See the FAQ item <span class="text-decoration-underline" @click="openPanel(1)">"How does it work?"</span> for more on this.</p>
          <p>Now sit back and wait for your responses to start coming in!</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-subtitle-1 text-sm-h5">
          <template v-slot:actions>
            <v-icon>
              mdi-eye-outline
            </v-icon>
          </template>
          How do I monitor an active study?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-5">Once launched, a study will be in ACTIVE mode. Expand the STUDY DETAILS panel to see high-level information about the study, such as the number of completed participants, or the median time it has taken for participants to finish the study.</p>
          <p>When looking at a QUESTION, you'll see the following:</p>
          <ul>
            <li>An OVERVIEW tab, which gives you the current estimate (where applicable) and the number of participants.</li>
            <li>An ESTIMATES tab showing the aggregated estimate for each scenario option or the distribution of estimates for binary questions.</li>
            <li>A GRAPH tab, which shows how the estimates have developed over time, and also any representative reasons seen by participants at any given point in time, giving you a sense of what reasons might be driving the estimates.</li>
            <li>A CONVERSATIONS tab, which shows you the full conversation for each participant, including the reasons offered, the responses given to those by participants, any representative reasons seen, as well as any revised estimates.</li>
            <li>A TOPICS and a DRIVERS tab, which enable you to find topic clusters in your data, and also see what topics are driving what estimates.</li>
          </ul>
          <p class="mt-2">Once you've collected all of your responses, you can CLOSE your study. Note that, after that, participants will no longer be able to take part in the study.</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-subtitle-1 text-sm-h5">
          <template v-slot:actions>
            <v-icon>
              mdi-share-variant
            </v-icon>
          </template>
          How do I share results?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-5">While your study is ACTIVE or CLOSED, you can share your study results as follows:</p>
          <ul>
            <li>Navigate to the relevant study from the YOUR STUDIES list, available in the sidebar.</li>
            <li>Expand the STUDY DETAILS box to copy the 'Share results link'.</li>
          </ul>
          <p class="mt-2">Share the link with whomever you want to be able to see your results, whether live or after the study has been closed.</p>
          <p>Please note that, by clicking the link, the recipient will get a read-only version of the study results, which includes all of the analytics you yourself can see in your study view. So only share this link with people who you are willing to share all of this intelligence with.</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-subtitle-1 text-sm-h5">
          <template v-slot:actions>
            <v-icon>
              mdi-coin
            </v-icon>
          </template>
          How do I get credits?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="mt-5">Each panel credit entitles you to 1 answer from 1 panellist from our external panel integrations (see <span class="text-decoration-underline" @click="openPanel(4)">"How do I recruit participants?"</span>). So, if you want to ask two questions of 200 panellists, that will cost you 2 x 200 = 400 credits.</p>
          <p>To see how many credits you have you can either:</p>
          <ul>
            <li>Click the white avatar at the top right of the screen; or</li>
            <li>Click YOUR CREDITS in the sidebar.</li>
          </ul>
          <p class="mt-2">To buy more credits, either click the white avatar and then BUY CREDITS, or navigate to the YOUR CREDITS from the sidebar.</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="mt-10" align="center" justify="center">
      <h2>Still have questions? Get in touch.</h2>
      <h3><a class="white--text" style="text-decoration: none;" href="mailto:info@masspredict.io">info@masspredict.io</a></h3>
    </div>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
      openedPanel: null,
    };
  },
  methods: {
    openPanel (index) {
      this.openedPanel = index;
    }
  },
};
</script>
<style>
a { 
  text-decoration: none; 
}

a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: lightgrey;
}

/* selected link */
a:active {
  color: white;
}

</style>
